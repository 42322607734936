<template>

    <div class="flex flex-col flex-grow text-black ">

        <div class="border-b border-zinc-300 p-5 md:p-8 w-full bg-zinc-100 flex flex-row justify-between">
            <img src="@/assets/logo.png" class="w-24 md:w-28 ml-[10%]">
            <!-- <div class="mr-[20px] :md:mr-[40px]">
                <a href="https//t.me/drophuntnews?start=S_1" class="b4 bb">Follow <img src="@/assets/social/t-b.svg" class="w-5 h-5 inline-block mx-1 mb-1"> News</a>
            </div> -->
        </div>
        <div class="flex flex-col md:flex-row flex-grow bg-[url('@/assets/hero1.jpg')] bg-cover max-mp:bg-right bg-center md:border-[40px] border-white border-[20px] max-md:py-10">

            <div class="md:w-1/2 p-5 md:p-10 flex flex-col gap-2 justify-center items-center">

                <div class="w-full md:w-[70%] max-w-[500px] mx-auto min-w-[340px] flex flex-col gap-2 max-md:opacity-90">
                 
                    <div class=" bg-white rounded-t-2xl shadow-xl p-5">
                        <h2 class="mb-2 text-2xl">
                            <!-- Unleash the Crypto Explorer Within -->
                            Awaken the Web3 Explorer Within
                        </h2>
                        <div class="mb-3 farro text-zinc-800">
                            Embark on the quest for legendary airdrops and giveaways!
                            <!-- Hunt for promising airdrops and giveaways, all in one place! -->
                        </div>
                        <div class="border border-zinc-300 rounded-xl p-4">
                            <div class="mb-3">
                                <div class="mb-2 text-zinc-800">Open the Telegram miniapp:</div>
                                <a href="https://t.me/drophuntbot?start=S_1" class="b3 bc">Start <img src="@/assets/social/t.svg" class="w-5 h-5 inline-block mx-1 mb-1"> Bot</a>
                            </div>
                            <div class="mb-2  text-zinc-800">or login with Telegram in a browser:</div>
                            <div>
                                <telegramLogin
                                        mode="callback"
                                        telegram-login="drophuntbot"
                                        @loaded='telegramLoadedCallbackFunc'
                                        @callback="telegramAuth"
                                    />
                                    <button @click="testTelegram()">Test</button>
                            </div>
                        </div>
                    </div>

                    <div class=" bg-white rounded-b-2xl shadow-xl p-5">
                        <h2>Advertisers Wanted</h2>
                        <div class="mt-1 mb-2">Elevate your TMA reach &mdash; request early access to self-service cabinet:</div>
                        <router-link :to="{ name: 'agency' }" class="b4 bb">Apply Today</router-link>
                    </div>

                </div>

            </div>

            <div class="md:w-1/2 p-5 md:p-10 md:flex md:flex-row md:justify-center md:items-center hidden">

               

            </div>

        </div>

        <div class="border-t border-zinc-200 px-5 p-3 md:px-[40px] md:p-5 w-full flex flex-col md:flex-row text-sm text-zinc-400 justify-between">
            <div>&copy 2024</div>
            <div>
                <router-link :to="{ name: 'terms' }">Terms</router-link>
                &#183;
                <router-link :to="{ name: 'privacy' }">Privacy</router-link>
            </div>
        </div>

    </div>

</template>

<script>
import { telegramLoginTemp } from 'vue3-telegram-login';
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import api from '@/api';

export default {
    components: {
        telegramLogin: telegramLoginTemp,
    },

    data() {
        return {

            DEBUG: false,

            initData: null,
            teleData: null,

            isAuth: false,

            isLoading: false,
            isError: false,

        }
    },
    computed: {
        // ...mapState(['appData', 'appLoad', 'appError', 'appErrorCode', 'appErrorText']),
        ...mapState(['user', 'userData']),
        isAuth() { return this.juser; },
        // ...mapState(['jwt']),
        // isAuth() { return !!this.jwt; },
    },

    mounted() {

        if(process.env.VUE_APP_DEBUG==1) this.DEBUG=true;

    },
    methods: {

        ...mapActions('auth', ['authWebpage', 'authMiniapp']),
        ...mapActions(['showNotification']), // Make sure to map this action

        async telegramAuth(user) {
            // alert(JSON.stringify(user));
            try{
              console.log(JSON.stringify(user)); // !!
              await this.authWebpage(user);
            } catch (error) {
                console.error('Telegram authentication failed:', error);
                this.showNotification({
                    type: 'error',
                    message: error.message || 'Telegram authentication failed. Please try again.'
                });
            }
        },

        async testTelegram() {
            const data = {"id":10000000000}; // !!
            await this.telegramAuth(data);
        },

    }, 
   
}
</script>

