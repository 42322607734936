import { createStore } from 'vuex';

import auth from './modules/auth';
import wallet from './modules/wallet';

// import axios from 'axios';
import api from '@/api'; 

const store = createStore({
  modules: {
    auth,
    wallet
  },
  state: {
    // general app
    appData: null,
    // user
    user: false,
    userData: null,
    // sess and auth
    sessionToken: localStorage.getItem('sessionToken'),
    // url params
    utmSource: '',
    utmMedium: '',
    utmCampaign: '',
    // utmData: '',
    codeBot: localStorage.getItem('codeBot'),
    codeInvite: localStorage.getItem('codeInvite'),

    // notification
    notification: null,
    miniAppHeight: null,
  },
  mutations: {
    // general app
    SET_APP_DATA(state, data) {
      state.appData = data;
    },
    // user
    SET_USER(state, userData) {
        if(userData) {
            state.user = true;
            state.userData = userData;
        } else {
            state.user = false;
            state.userData = null;
        }
    },
    // sess and auth
    SET_SESSION_TOKEN(state, token) {
      state.sessionToken = token;
      console.log('SET sessionToken: '+token);
      localStorage.setItem('sessionToken', token);
    },
    // get params
    SET_UTM_SOURCE(state, source) {
      state.utmSource = source;
    },
    SET_UTM_MEDIUM(state, medium) {
      state.utmMedium = medium;
    },
    SET_UTM_CAMPAIGN(state, campaign) {
      state.utmCampaign = campaign;
    },
    // SET_UTM_DATA(state, data) {
    //   state.utmData = data;
    // },
    SET_CODE_BOT(state, code) {
      state.codeBot = code;
      localStorage.setItem('codeBot', code);
    },
    SET_CODE_INVITE(state, code) {
      state.codeInvite = code;
      localStorage.setItem('codeInvite', code);
    },
    
    SET_NOTIFICATION(state, notification) {
      state.notification = notification;
    },
    SET_MINI_APP_HEIGHT(state, height) {
      state.miniAppHeight = height;
    },
  },
  actions: {

    setGetParams({ commit }, { utmSource = '', utmMedium = '', utmCampaign = '', inviteCode = null, botCode = null }) {
        console.log('setGetParams: '+utmSource+' '+utmMedium+' '+utmCampaign+' '+botCode+' '+inviteCode); // !!
        commit('SET_UTM_SOURCE', utmSource);
        commit('SET_UTM_MEDIUM', utmMedium);
        commit('SET_UTM_CAMPAIGN', utmCampaign);
        // commit('SET_UTM_DATA', utmData);
        if(botCode) commit('SET_CODE_BOT', botCode);
        if(inviteCode) commit('SET_CODE_INVITE', inviteCode);
    },

    showNotification({ commit }, { message, type }) {
      commit('SET_NOTIFICATION', { message, type });
      setTimeout(() => {
        commit('SET_NOTIFICATION', null);
      }, 5000);
    },

    hideNotification({ commit }) {
        setTimeout(() => {
        commit('SET_NOTIFICATION', null);
        }, 1);
    },

    setCodeBot({ commit }, code) {
        commit('SET_CODE_BOT', code);
    },
    setCodeInvite({ commit }, code) {
        commit('SET_CODE_INVITE', code);
    },
    
    //# Get User Data #//

    async getUserData({ commit }, data = null ) {
        try {
            if(data) {
                commit('SET_USER', data);
                return data;
            }
            console.log('retreiving getUserData');
            const response = await api.post('user/data', {});
            if(response.data.status === 'error') {
              commit('SET_USER', null);
              throw new Error(response.data.message);
            }
            if(response.data && response.data.user_data) {
               commit('SET_USER', response.data.user_data);
                // alert('getUserData: '+JSON.stringify(response.data.user_data));
            }
            //   const response = await axios.post(process.env.VUE_APP_API_URL+'user/data',
            //         { auth: jwt }
            //   );
            //   if(response.data && response.data.user_data) {
            //         commit('SET_USER', response.data.user_data);
            //   }
            return response.data;
        } catch (error) {
            console.error('[store/getUserData] Failed to get user data:', error);
            throw error;
        }
    },

    setAppHeight({ commit }, height) {
      commit('SET_MINI_APP_HEIGHT', height);
      document.documentElement.style.setProperty('--mini-app-height', `${height}px`);
    },
  },
});

export default store;