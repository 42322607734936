import { createApp } from 'vue'
import App from './App.vue'
import { globalErrorHandler, unhandledRejectionHandler } from './error'

import router from './router';
import i18n from './lang';
import store from './store';

import Globals from './func';

import './assets/index.css';

import TelegramWebAppMock from './telegramWebAppMock'

// Set up the mock in development mode
if (process.env.NODE_ENV === 'development') {
  window.Telegram = {
    WebApp: TelegramWebAppMock
  }
}

const app = createApp(App);

window.addEventListener('unhandledrejection', unhandledRejectionHandler);

app.config.errorHandler = globalErrorHandler;
app.mixin(Globals);

app
.use(router)
.use(i18n)
.use(store)
.mount('#app')