import { createRouter, createWebHistory } from 'vue-router';

const routes = [

  // { path: '/', name: 'home', component: () => import('@/pages/Dash/Home.vue') },
  { path: '/hubs', name: 'list', component: () => import('@/pages/Dash/Hubs.vue'), props: true },
  { path: '/hub/:id', name: 'info', component: () => import('@/pages/Dash/HubInfo.vue'), props: true },
  { path: '/', name: 'task', component: () => import('@/pages/Dash/Task.vue'), props: true },
  { path: '/completed', name: 'completed', component: () => import('@/pages/Dash/Completed.vue'), props: true },
  // { path: '/quiz', name: 'quiz', component: () => import('@/pages/Quiz.vue'), props: true },

  { path: '/wallet', name: 'wallet', component: () => import('@/pages/Dash/Wallet/Index.vue'), props: true, children: [
    { path: '/balance', name: 'balance', component: () => import('@/pages/Dash/Wallet/Balance.vue'), props: true },
    { path: '/swap', name: 'swap', component: () => import('@/pages/Dash/Wallet/Swap.vue'), props: true },
    { path: '/withdraw', name: 'withdraw', component: () => import('@/pages/Dash/Wallet/Withdraw.vue'), props: true },
  ], redirect: { name: 'balance' }
  },

  { path: '/bags', name: 'bags', component: () => import('@/pages/Dash/Bags.vue'), props: true },

  { path: '/points', name: 'points', component: () => import('@/pages/Dash/Points.vue'), props: true },
  { path: '/invite', name: 'invite', component: () => import('@/pages/Dash/Invite.vue'), props: true },

  { path: '/about', name: 'about', component: () => import('@/pages/Docs/About.vue'), props: true },
  { path: '/terms', name: 'terms', component: () => import('@/pages/Docs/Terms.vue'), props: true },
  { path: '/privacy', name: 'privacy', component: () => import('@/pages/Docs/Privacy.vue'), props: true },

  { path: '/feedback', name: 'feedback', component: () => import('@/pages/Feedback.vue'), props: true },
  { path: '/agency', name: 'agency', component: () => import('@/pages/Agency.vue'), props: true },
    // { path: '/my', name: 'my', component: () => import('@/pages/Dash/ListMy.vue'), props: true },
  // { path: '/history', name: 'history', component: () => import('@/pages/History.vue'), props: true },

  { path: '/user', name: 'user', component: () => import('@/pages/User/User.vue'), props: true },
  { path: '/settings', name: 'settings', component: () => import('@/pages/User/Settings.vue'), props: true },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
                const element = document.querySelector(to.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            return { top: 0 } // return { x: 0, y: 0 }
        }
      },
  });

// router.beforeEach((to, from, next) => {
//   const isAuthenticated = !!localStorage.getItem('wallet');
//   if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
//     next({ name: 'Home' });
//   } else {
//     next();
//   }
// });

export default router;