import store from './store';

// export function globalErrorHandler(error, vm, info) {
//   // Log the error
//   console.error('Uncaught error:', error);
//   console.error('Vue instance:', vm);
//   console.error('Error info:', info);

//   // You can send error to a logging service here
//   // Display a user-friendly error message
//   showErrorNotification('An unexpected error occurred. Please try again later.');
// }

// function showErrorNotification(message) {
//   // Implement this function to show a notification to the user
//   // This could be a custom notification component, alert, or toast
//   // For example:
//   alert('Global error: '+message);
// }

export const globalErrorHandler = (err, vm, info) => {
    let errorDetails = {
        message: err.message || 'Unknown error',
        stack: err.stack || 'No stack trace available',
        componentName: vm?.$options?.name || 'Unknown component',
        info: info || 'No additional information',
        type: 'Vue Error'
    };

    const errorString = JSON.stringify(errorDetails, null, 2);

    console.error('Vue Error:', errorString);

    store.dispatch('showNotification', {
        message: `An error occurred: ${errorDetails.message} Please try again later or reload the app.`,
        type: 'error'
    });

    // Optionally, you can send this error to your server for logging
    // api.post('log-error', errorDetails).catch(console.error);
};

export const unhandledRejectionHandler = (event) => {
    let errorDetails = {
        message: event.reason?.message || 'Unknown error',
        stack: event.reason?.stack || 'No stack trace available',
        type: 'Unhandled Promise Rejection'
    };

    const errorString = JSON.stringify(errorDetails, null, 2);

    console.error('Unhandled Promise Rejection:', errorString);

    if (process.env.NODE_ENV === 'development') {
        store.dispatch('showNotification', { 
            message: `An unexpected error occurred: ${errorDetails.message} Please try again later or reload the app.`, 
        type: 'error' 
      });
    }

    event.preventDefault();
};