import axios from 'axios';
import api from '@/api';

export default {
  namespaced: true,
  state: {
    jwt: localStorage.getItem('jwt'),
    refreshToken: localStorage.getItem('refreshToken'),
  },
  mutations: {

    SET_AUTH_JWT(state, token) {
      state.jwt = token;
      if (token) {
        localStorage.setItem('jwt', token);
      } else {
        localStorage.removeItem('jwt');
      }
      console.log('[store/auth] SET_AUTH_JWT '+token);
    },

    SET_AUTH_RT(state, token) {
      state.refreshToken = token;
      if (token) {
        localStorage.setItem('refreshToken', token);
      } else {
        localStorage.removeItem('refreshToken');
      }
      console.log('[store/auth] SET_AUTH_RT '+token);
    },

    CLEAR_AUTH(state) {
      state.jwt = null;
      state.refreshToken = null;
      localStorage.removeItem('jwt');
      localStorage.removeItem('refreshToken');
      console.log('[store/auth] CLEAR_AUTH');
    },
    
  },
  actions: {

    async authWebpage({ commit, dispatch, rootState }, user) {
      try {
        const response = await api.post('auth/webpage', {
          data: user,
          gets: {
            utm_source: rootState.utmSource,
            utm_medium: rootState.utmMedium,
            utm_campaign: rootState.utmCampaign,
            code_invite: rootState.codeInvite,
          }
        });

        if (response.data.status === 'success') {
          commit('SET_AUTH_JWT', response.data.auth_jwt);
          commit('SET_AUTH_RT', response.data.auth_rt);
          await dispatch('getUserData', response.data.user_data, { root: true });
        } else {
          throw new Error(response.data.message || 'Authentication failed');
        }
      } catch (error) {
        console.error('Error in authWebpage:', error);
        dispatch('showNotification', {
          message: error.message || 'Authentication failed',
          type: 'error'
        }, { root: true });
        throw error;
      }
    },

    async authMiniapp({ commit, rootState, dispatch }, teleData) {
      try {
        if (!teleData.initData) {
          throw new Error('No initData provided');
        }
        console.log('[store/auth/authMiniapp] teleData '+JSON.stringify(teleData));
        const response = await axios.post(process.env.VUE_APP_API_URL + 'auth/miniapp', {
          data: 
            teleData
          // { 
          //   initData: teleData.initData,
          //   initDataUnsafe: teleData.initDataUnsafe,
          //   version: teleData.version,
          //   platform: teleData.platform,
          //   user: teleData.user,
          //   // colorScheme: teleData.colorScheme,
          //   // themeParams: teleData.themeParams,
          //   // isExpanded: teleData.isExpanded,
          //   // viewportHeight: teleData.viewportHeight
          // }
          ,
          gets: {
            utm_source: rootState.utmSource,
            utm_medium: rootState.utmMedium,
            utm_campaign: rootState.utmCampaign,
            code_invite: rootState.codeInvite,
            // codeBot: rootState.codeBot,
          }
        });
        // const response = await api.post('auth/miniapp', {
        //   data: teleData,
        //   gets: {
        //     utm_source: rootState.utmSource,
        //     utm_medium: rootState.utmMedium,
        //     utm_campaign: rootState.utmCampaign,
        //     code_invite: rootState.codeInvite,
        //     // codeBot: rootState.codeBot,
        //   }
        // });
        // alert('authMiniapp response '+JSON.stringify(response.data));
        if (response.data.status === 'success') {
            commit('SET_AUTH_JWT', response.data.auth_jwt);
            commit('SET_AUTH_RT', response.data.auth_rt);
            dispatch('getUserData', response.data.user_data, { root: true });
        } else {
          throw new Error(response.data.message || 'Authentication failed');
        }
      } catch (error) {
        console.error('Error in authMiniapp:', error);
        dispatch('showNotification', {
          message: error.message || 'Authentication failed',
          type: 'error'
        }, { root: true });
        dispatch('logout');
        throw error;
      }
    },

    async refreshAuth({ commit, dispatch, state }) {
        try {
            // Not using api.post here as it might create a loop with the interceptor
            console.log('Store refreshToken '+state.refreshToken);
            const response = await axios.post(process.env.VUE_APP_API_URL+'auth/refresh', {
                auth_rt: state.refreshToken
            });
            console.log('Store refreshToken response '+JSON.stringify(response.data));
            if (response.data.status === 'success') {
                commit('SET_AUTH_JWT', response.data.auth_jwt);
                commit('SET_AUTH_RT', response.data.auth_rt);
                dispatch('getUserData', response.data.user_data, { root: true });
                return { jwt: response.data.auth_jwt, status: 'success' };
            } else {
                dispatch('logout');
                console.log('Error refreshing authorization token '+JSON.stringify(response.data));
                throw new Error('Error refreshing authorization token '+JSON.stringify(response.data));
            }
        } catch (error) {
            dispatch('logout');
            console.error('Failed to refresh token:', error);
            throw new Error('Failed to refresh token' + error?.message);
            // dispatch('showNotification', {
            //     message: 'Unable to refresh authorization token. Please reconnect the wallet.',
            //     type: 'error'
            // }, { root: true });
            // disconnect wallet ?
            // commit('CLEAR_AUTH');
            // this.logout();
            // throw error;
        }
    },

    logout({ commit }) {
        console.log('[store/auth] logout');
        commit('CLEAR_AUTH');
        commit('SET_USER', null, { root: true });
    },

  },
};