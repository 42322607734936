<template>

    <div class="flex flex-col min-h-screen">
        <!-- :class="miniApp ? 'miniapp-height' : 'min-h-screen'" -->
        
        <Notification/>

        <div v-if="appLoading" class="p-10 w-full flex-grow">
            
            <div class="mx-auto my-auto text-center pt-20">
                <img src="@/assets/logo.png" class="w-24 md:w-28 mb-10 mx-auto">
                <div class="spinner mx-auto mb-4"></div>
                Loading App...
            </div>

        </div>
        <div v-else-if="appError" class="p-10 w-full flex-grow">

            <div class="mx-auto my-auto text-center pt-20">
                <img src="@/assets/logo.png" class="w-24 md:w-28 mb-3 mx-auto">
                <div class="text-sm text-zinc-500 mb-10">
                    V{{ appVersion }}
                </div>
                <!-- <div class="spinner mx-auto mb-4"></div> -->
                <div class="mb-10">{{ appErrorText }}</div>
                <button @click="reloadApp" class="b3 bg">Reload</button>
            </div>
        
        </div>
        <div v-else class="flex flex-col flex-grow">

            <!-- 
            <div class="debug" v-if="DEBUG">
                user = {{  user }}<br>
                userData = {{  userData }}<br>
            </div> 
            <button class="b5 ba" @click="doDEBUG">DEBUG</button>
            -->

            <!-- Web page to present an app to users who are not logged in -->
            <div v-if="!isAuth" class="text-black md:min-h-screen flex-grow  flex flex-col">

                <HomeNoAuth/>

            </div>
            <div v-else class="md:min-h-screen flex-grow flex flex-col">

                <!-- <BotStart/> -->
                <Inside :miniApp="miniApp" />
                
            </div>

        </div>

    </div>

</template>
  
<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import api from '@/api';

import HomeNoAuth from '@/pages/Home.vue';
import Inside from '@/pages/Inside.vue';
// import BotStart from '@/components/BotStart.vue';

import MainMenu from '@/components/MainMenu.vue';
import UserProfile from '@/components/UserProfile.vue';
import Notification from '@/components/Notify.vue';

import tonConnectMixin from './mixins/tonConnectMixin';

export default {
    name: 'DropHunt',
    mixins: [tonConnectMixin],
    components: {
        MainMenu,
        UserProfile,
        Notification,
        HomeNoAuth,
        Inside,
        // BotStart,
    },
    watch: {
        '$route'() {
            this.checkRoute();
        },
    },
    data() {
        return {

            DEBUG: false,

            // Data: null,
            // User: null,

            // openMenu: false,
            // openProfile: false,

            initData: null,
            teleData: {},

            miniApp: null,
            standaloneApp: null,
            // isWebApp: false,

            appVersion: '',

            isAuth: false,
            doAuth: true,
            walletRaw: '-',

            statsNet: '-',
            statsVol: '-',

            appLoading: true,
            appError: false,
            appErrorText: null,

            miniAppHeight: 0,

            isHome: false, // watch route

        }
    },
    
    computed: {
        ...mapState(['notification']),
        // ...mapState(['appData', 'appLoad', 'appError', 'appErrorCode', 'appErrorText']),
        ...mapState(['user', 'userData']),
        isAuth() { return !!this.user; },
        // isAuth() { return this.user; },
        // ...mapState(['auth/jwt']),
        // isAuth() { return !!this.auth.jwt; },
        // ...mapState(['startBotCode', 'startBotShow']),
        ...mapState(['walletAddr', 'walletData', 'walletConnected', 'walletConnecting']),
        // ...mapState(['codeBot', 'codeInvite']),

        // miniAppHeightClass() {
        //     alert('miniAppHeightClass'+this.miniApp);
        //     if(this.miniApp) return 'mini-app-height bg-red-500';
        //     else return 'min-h-screen bg-green-500';
        //     // return this.miniApp ? 'mini-app-height bg-red-500' : 'min-h-screen bg-green-500';
        // },
    },
    watch: {
        '$route'() {
            this.checkRoute();
        },
        // user(newValue) {
        //     console.log('User state changed:', newValue);
        // }
    },
    async created() {

        // GET parameters
        await this.getParams();

        await this.initApp();

        // await this.initSession();

        if (window.Telegram?.WebApp && window.Telegram.WebApp.initData) { //

            // alert('miniapp '+JSON.stringify(window.Telegram.WebApp));

            this.miniApp = true;

            // this.initData = window.Telegram.WebApp.initData;

            Object.assign(this.teleData, {
                initData: window.Telegram.WebApp.initData,
                initDataUnsafe: window.Telegram.WebApp.initDataUnsafe, // contains query_id, user, auth_date, hash
                version: window.Telegram.WebApp.version,
                platform: window.Telegram.WebApp.platform,
                colorScheme: window.Telegram.WebApp.colorScheme,
                themeParams: window.Telegram.WebApp.themeParams,
                isExpanded: window.Telegram.WebApp.isExpanded,
                viewportHeight: window.Telegram.WebApp.viewportHeight,
                viewportStableHeight: window.Telegram.WebApp.viewportStableHeight,
                headerColor: window.Telegram.WebApp.headerColor,
                backgroundColor: window.Telegram.WebApp.backgroundColor,
                isClosingConfirmationEnabled: window.Telegram.WebApp.isClosingConfirmationEnabled,
            });

            this.setMiniAppHeight();

            try {
                await this.authMiniapp(this.teleData);
                // If successful, you might want to update UI or perform additional actions
            } catch (error) {
                console.error('Mini app authentication failed:', error);
                this.$store.dispatch('showNotification', {
                    type: 'error',
                    message: 'Mini app authentication failed: ' + error.message,
                });
                // Error is already handled in the action, no need to show notification here
            }

            try {
                window.Telegram.WebApp.enableClosingConfirmation();
                window.Telegram.WebApp.disableVerticalSwipes();
                window.Telegram.WebApp.expand(); // Telegram.WebApp.expand();
            } catch (error) {
                // console.error('Error expanding Telegram WebApp:', error);
            }

        } else {
            this.miniApp = false;
            // await this.authWebpage();
        }

        // this.statsNet = this.appData.stats.net;
        // this.statsVol = this.appData.stats.vol;

        // const startBotCode = localStorage.getItem('startBotCode');
        // if (startBotCode) {
        //     this.$store.commit('SET_START_BOT_CODE', startBotCode);
        // } else {
        //     console.log('No startBotCode found in local storage.');
        // }

        // // Check/initialize session
        // try {
        //     await this.initSession();
        //     // Session is now initialized or loaded
        // } catch (error) {
        //     console.error('Failed to initialize/load session:', error)
        //     // Error handling is now done in the store action
        // }
        
    },
    mounted() {

        if(process.env.VUE_APP_DEBUG==1) this.DEBUG=true;

        this.appVersion = process.env.VUE_APP_API_VER;

        // this.WebAppData = window.Telegram.WebApp; // ???

        // alert('window.Telegram = '+JSON.stringify(window.Telegram));
        // alert('window.Telegram.WebApp = '+JSON.stringify(window.Telegram.WebApp));
        // window.Telegram = {"WebApp":{"initData":"query_id=[...]":false}}

        this.setMiniAppHeight();
        window.addEventListener('resize', this.setMiniAppHeight);

    },
    methods: {

        ...mapActions(['showNotification']),
        ...mapActions(['getUserData']),

        async initApp() {
            try {
                const response = await api.post('init', {});
                if (response.data.status === 'success') {
                    if (response.data.version === process.env.VUE_APP_API_VER) {
                        this.$store.commit('SET_APP_DATA', response.data.app_data);
                        if (this.$store.state.auth.jwt) {
                            await this.getUserData();
                        }
                    } else {
                        throw new Error(`Expired version. Please reload the app - version ${response.data.version} is required.`);
                    }
                } else {
                    throw new Error('Unable to connect to server.');
                }
            } catch (error) {
                console.error('Error initializing app:', error);
                this.appError = true;
                this.appErrorText = error.message || 'Error connecting to server.';
                this.$store.dispatch('showNotification', {
                    type: 'error',
                    message: this.appErrorText,
                });
            } finally {
                this.appLoading = false;
            }
        },

        ...mapActions('auth', ['authWebpage', 'authMiniapp']),

        ...mapActions(['initSession', 'setGetParams']),
        ...mapActions(['setCodeBot', 'setCodeInvite']),

        ...mapActions(['doConnect', 'doDisconnect']), // ?
        ...mapActions(['signWallet', 'disconnectWallet', 'connectWallet']),

        // ...mapActions(['auth/logout']),
        ...mapActions('auth', ['logout']),

        reloadApp() {
            window.location.reload(true); // Forces a hard reload
        },


        // Utility function to get UTM params
       //* Save GET Params *//

       async getParams() {

            const urlParams = new URLSearchParams(window.location.search);

            const inviteCode = urlParams.get('i') || null;

            const utmSource = urlParams.get('utm_source') || urlParams.get('from') || '';
            const utmCampaign = urlParams.get('utm_campaign') || '';
            const utmMedium = urlParams.get('utm_medium') || '';

            console.log('GET' +JSON.stringify({ utmSource, utmCampaign, utmMedium, inviteCode }));
            await this.setGetParams({ utmSource, utmCampaign, utmMedium, inviteCode });

        },


        //* Save GET Params *//

        // async getParams() {

        //     const urlParams = new URLSearchParams(window.location.search);

        //     const inviteCode = urlParams.get('i') || null;

        //     const utmSource = urlParams.get('utm_source') || urlParams.get('from') || '';
        //     const utmCampaign = urlParams.get('utm_campaign') || '';
        //     const utmMedium = urlParams.get('utm_medium') || '';

        //     // const utmData = {};
        //     // for (const [key, value] of urlParams.entries()) {
        //     //     if (key.startsWith('utm_')) {
        //     //     utmData[key] = value;
        //     //     }
        //     // }
        //     // const utmDataJson = JSON.stringify(utmData);

        //     console.log('GET' +JSON.stringify({ utmSource, utmCampaign, utmMedium, inviteCode }));
        //     await this.setGetParams({ utmSource, utmCampaign, utmMedium, inviteCode });

        // },

        //* Utilities *//
    
        // scrollStop() { document.body.classList.add('no-scroll'); },
        // scrollStart() { document.body.classList.remove('no-scroll'); },

        isActive(name) { return this.$route.name === name; },

        closeBotShow() {
            this.$store.commit('SET_START_BOT_SHOW', false);
        },

        doMenu () { this.openMenu = !this.openMenu; },
        // doSignUp () { this.openSignUp = !this.openSignUp; },
        // doSignIn () { this.openSignIn = !this.openSignIn; },
        doProfile () { this.openProfile = !this.openProfile; },

        hideNotify() {
            this.$store.dispatch('hideNotification');
            this.notification = false;
        },

        closeTxSuccessModal() {
            this.$store.commit('SHOW_TX_SUCCESS_MODAL', false);
        },
        closeTxErrorModal() {
            this.$store.commit('SHOW_TX_ERROR_MODAL', false);
        },

        checkRoute() { // Watch route changed
            // if(this.$route.name=='home') this.isHome = true;
            if(this.$route.name=='about' || this.$route.name=='terms' || this.$route.name=='privacy' || this.$route.name=='agency') this.doAuth = false;
            else this.doAuth = true;
        },

        doDEBUG() { this.DEBUG = !this.DEBUG; },

        setMiniAppHeight() {
        //     alert('setMiniAppHeight'+window.Telegram.WebApp.viewportHeight);
            if (this.miniApp && window.Telegram?.WebApp?.viewportHeight) {
        //         const height = `${window.Telegram.WebApp.viewportHeight}px`;
        //         document.documentElement.style.setProperty('--mini-app-height', height);
        //         // Also set the min-height directly on the element
        //         // document.querySelector('.mini-app-height').style.minHeight = height;
                    this.miniAppHeight = window.Telegram.WebApp.viewportHeight;
            }
        //     this.miniAppHeightClass = this.miniApp ? 'mini-app-height bg-red-500' : 'min-h-screen bg-green-500';
        },

    }, // methods end
    beforeUnmount() {
        // this.removeAllListeners();
        // window.removeEventListener('message', this.handleTelegramAuth);
        window.removeEventListener('resize', this.setMiniAppHeight);
    },
    beforeDestroy() {
        // this.scrollStop();
        if (this.TONConnection) {
            this.TONConnection.onStatusChange(null);
        }
    },
    deactivated() {
        // this.scrollStop();
    },

}
</script>

<style>
.miniapp-height {
    min-height: v-bind('miniAppHeight + "px"');
    /* min-height: var(--tg-viewport-height); */
    background-color: #099;
}
</style>